import React, {useEffect, useState} from "react"
import parse from 'html-react-parser'
import Img from 'gatsby-image';
import { Helmet } from "react-helmet";
import {Link} from 'gatsby';
import { navigate } from "gatsby"
import "../sass/pages/_case_studies-archive.scss";
import { graphql } from 'gatsby'

const CaseStudies = ({ data, pageContext }) => {
  //console.log(pageContext)
  const page = data.wpPage;
  const posts = data.allWpCaseStudy;
  const cats = data.allWpCaseCat;
  const {totalPages, pageNumber} = pageContext;
  const [isActive, setIsActive] = useState(pageContext.taxonomy);
  

  // if(typeof window !== 'undefined'){
  //   if(window.location.pathname.includes('/case-studies/')){
  //     document.querySelector('body').setAttribute('data-bg-color', 'bg-white');
  //   }
  // }


  useEffect(() => {
    if(typeof document !== 'undefined'){
      document.querySelector('body').setAttribute('data-bg-color', 'bg-white')
      document.querySelector('body').setAttribute('data-class', '')
      
    }
  }, [])
  

  const classNames = {};
  posts.edges.forEach((post,i) => {

    if(post.node.acf_caseStudyFields.backgroundType === 'png-bg'){
      if(i%2 === 0){
        classNames[i] = 'left is_inview inview_check2 png-bg';
      }else{
        classNames[i] = 'right is_inview inview_check2 png-bg';
      }
    }else{
      if(i%2 === 0){
        classNames[i] = 'left is_inview inview_check2 cover';
      }else{
        classNames[i] = 'right is_inview inview_check2 cover';
      }
    }
    
  });
 
  // const checkActive = (slug) => {
  //   if(typeof window !== 'undefined'){
  //     if(window.location.pathname.includes(slug)){
  //       setIsActive(slug);
  //     }
  //   }
  // }

  const handleOnChange = (e) => {
    var index = e.target.selectedIndex;
    var optionElement = e.target.childNodes[index]
    var option =  optionElement.getAttribute('data-slug');
    var cat =  optionElement.getAttribute('data-cat');
    
    navigate(option)
    setIsActive(cat);

  }

  
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, follow" />
      </Helmet>
      <div className="case_studies">
        <section className="intro is_inview">
          <div className="wrapper">
            <div className="left">
              <h1 className="cats_heading">{pageContext.taxonomy.toUpperCase()} Case Studies</h1>
              <section className="filters">
                <ul>
                  <li><Link to={'/case-studies/'} data-cat="*">All Case Studies</Link></li>
                  {cats.nodes.map((cat, index) => {
                    
                    if(cat.count != null){
                      return(<li key={cat.slug+" "+index} className={isActive === cat.slug ? 'active' : '' }><Link to={'/case-studies/'+cat.slug+'/'} className={cat.slug}>{cat.name}</Link></li>)
                    }                   
                  })}
                </ul>
                <select name="filters"  onChange={(e) => handleOnChange(e)} defaultValue={isActive}>
                <option data-cat="All Case Studies" data-slug={'/case-studies/'} >All Case Studies</option> 		
                  {cats.nodes.map((cat, index) => {
                    if(cat.count != null){
                      return(  <option key={cat.slug+"_select_"+index} data-cat={cat.slug} value={cat.slug} data-slug={'/case-studies/'+cat.slug+'/'}>{cat.name}</option>       )
                    }                   
                  })}	     			
                </select>
      
              </section>
            </div>
            <div className="right">
              
            </div>
          </div>
        </section>
        
        <section className="recent is_inview">
          <div className="wrapper">
            <div className="wrapper--inner">
              {
                posts.edges.map((post,i) => {
                  if(i < 4){
                    return(
                      <article 
                        data-id="" 
                        data-cat="" 
                        className={
                          classNames[i]
                        }
                      >
                        <Link to={post.node.link}>			

                          <div className="image-wrap">
                            <div className="tint"></div>
                            {post?.node?.acf_caseStudyFields?.backgroundImage?.localFile?.childImageSharp?.fluid ? '' : 'image not found'}
                            <Img fluid={post?.node?.acf_caseStudyFields?.backgroundImage?.localFile?.childImageSharp?.fluid } className="bg-cover BG bgStretch" />
                          </div>
                          <div className="info">
                            <div className="label">{post.node.acf_caseStudyFields.companyName}</div>
                            <h2>{post.node.excerpt.replace(/(<([^>]+)>)/gi, "")}</h2>
                          </div>
                        </Link>
                      </article>
                    )
                  }
                  i++;
                })
              }
            </div>
          </div>
        </section>
        
        
        <section className="additional_case_studies is_inview">
          <div className="wrapper">
          {
          posts.edges.length > 4 ? 
            <div className="label title">Additional<span className="dynamicName"></span> Case Studies</div>
            : ""
          }
           <div className={`the_posts ${totalPages > 1 ? 'no_pagination' : ""}`}>
            {
                posts.edges.map((post,i) => {
                  if(i >= 4){
                    return(
                      <article 
                        data-id="" 
                        data-cat="" 
                        className={
                         'post ' + classNames[i]
                        }
                      >
                        <Link to={post.node.link}>			

                          <div className="image-wrap">
                            <div className="tint"></div>
                            {post?.node?.acf_caseStudyFields?.backgroundImage?.localFile?.childImageSharp?.fluid ? '' : 'image not found'}
                            <Img fluid={post?.node?.acf_caseStudyFields?.backgroundImage?.localFile?.childImageSharp?.fluid } className="bg-cover BG bgStretch" />
                          </div>
                          <div className="info">
                            <div className="label">{post.node.acf_caseStudyFields.companyName}</div>
                            <h2>{post.node.excerpt.replace(/(<([^>]+)>)/gi, "")}</h2>
                          </div>
                        </Link>
                      </article>
                    )
                  }
                  i++;
                })
              }
           
              {  
              totalPages > 1 ?  
                <section className="pagination">
                  <div className="wp-pagenavi" role="navigation">
                      {pageNumber !== 1 ?
                          <Link className="previouspostslink" rel="previous" to={pageNumber === 2 ? '/case-studies/': `/case-studies/page/${pageNumber-1}/`} >Prev</Link>
                      : ""
                      }
                      {Array.from({ length: totalPages }, (_, i) => (
                          i === pageNumber - 1 ? 
                              <span aria-current="page" className="current">{i + 1}</span>
                          : 
                          <Link
                          className="page larger"
                          to={`${i === 0 ? '/case-studies/' : '/case-studies/page/'+ (i + 1)}/`}
                          >
                          {i + 1}
                          </Link>
                      ))}
                      {pageNumber !== totalPages ?
                          <Link className="nextpostslink" rel="next" to={`/case-studies/page/${pageNumber+1}/`}>Next</Link>
                      : ""
                      }
                      
                  </div>
                </section>
              : " "
              }
            </div>
          </div>
        </section>	
      </div>
    </>
  )
}

export default CaseStudies

export const query = graphql`

query CaseStudyCatsArchive($offset: Int!, $postsPerPage: Int!, $taxonomy: String!) {
  wpPage(id: {eq: "cG9zdDoxMTcy"}) {
    id
    title
    acf_introText {
      introText
    }
  }
  allWpCaseCat(
    sort: { order: ASC, fields: id }
  ){
    nodes {
      slug
      name
      link
      count
    }
  }
  allWpCaseStudy(
    sort: {order: DESC, fields: date}
    filter: {hideCaseStudyFromArchive: {hideThisCaseStudyFromArchive: {ne: true}}, caseCats: {nodes: {elemMatch: {slug: {eq: $taxonomy}}}}}
    limit: $postsPerPage
    skip: $offset
  ) {
    edges {
      node {
        id
        title
        excerpt
        link
        acf_caseStudyFields {
          backgroundImage {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          backgroundType
          caseStudyTitle
          colour
          companyName
        }
      }
    }
  }
}

    
`